import "./App.css";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { MenuDataService } from "./provider/services/menu-data.service";
import "react-toastify/dist/ReactToastify.css";

const Page404Error = React.lazy(() => import("./404/404"));
const Refund = React.lazy(() => import("./refund-policy/Refund"));
const Privacy = React.lazy(() => import("./refund-policy/Privacy"));
const Term = React.lazy(() => import("./refund-policy/Term"));
const Blogs = React.lazy(() => import("./blogs/Blogs.components"));
const Menu = React.lazy(() => import("./Menu/menu.component"));
const Home = React.lazy(() => import("./Home/home.component"));
const CartComponent = React.lazy(() => import("./Cart/cart.component"));
const Order = React.lazy(() => import("./Orders/Orders.component"));
const HowToUse = React.lazy(() => import("./HowToUse/Howtouse.components"));
const Contacts = React.lazy(() => import("./Contacts/Contact"));
const About = React.lazy(() => import("./About/About.component"));
// const Subscription = React.lazy(() => import("./SubscriptionCard/Subscription"));
let menuDataService = new MenuDataService();
function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/home" component={Home} exact />
          <Route path="/contact" component={Contacts} exact />
          <Route
            path="/menu"
            component={() => {
              return <Menu menuDataService={menuDataService} />;
            }}
            exact
          />
          <Route
            path="/cart"
            component={() => {
              return <CartComponent menuDataService={menuDataService} />;
            }}
            exact
          />
          <Route path="/about" component={About} exact />
          <Route path="/How-to-use" component={HowToUse} exact />
          <Route path="/my-orders" component={Order} exact />
          <Route path="/refund-policy" component={Refund} exact />
          <Route path="/Privacy-policy" component={Privacy} exact />
          <Route path="/term and conditions" component={Term} exact />
          <Route path="/blogs" component={Blogs} exact />
          <Route path="/blogs/:blogId/:blogTitle" component={Blogs} exact />
          <Route path="*" component={Page404Error} exact />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
