import { API_BASE_URL } from "../..";

class OrderService {
  #table;
  orderType;
  #userDetails;
  #restaurantUID;
  items = [];
  #note = "";
  config;
  deliveryCharges = 0;
  constructor() {
    this.items = JSON.parse(sessionStorage.getItem("cart")) || [];
  }
  addTableNumber(tableNumber) {
    this.#table = tableNumber;
  }
  setRestaurantUID(id) {
    this.#restaurantUID = id;
  }

  setConfig(config) {
    if (!!config) {
      this.config = config;
      this.deliveryCharges = config.deliveryCharges || 0;
    }
  }

  removeItems(item) {
    const index = this.getItemIndex(item);
    this.items.splice(index, 1);
    sessionStorage.setItem("cart", JSON.stringify(this.items));
  }

  addItems(items) {
    items.forEach((item) => {
      const index = this.getItemIndex(item);
      if (index >= 0) {
        if (item.quantity) {
          this.items[index] = item;
        } else {
          this.items = this.items.filter((e, i) => i != index);
        }
        sessionStorage.setItem("cart", JSON.stringify(this.items));
      } else {
        this.items.push(item);
        sessionStorage.setItem("cart", JSON.stringify(this.items));
      }
    });
  }

  handelItemQty(item, qty) {
    item.quantity += qty;
    const index = this.getItemIndex(item);
    if (index >= 0) {
      this.items[index] = item;
      sessionStorage.setItem("cart", JSON.stringify(this.items));
    } else {
      this.items.push(item);
      sessionStorage.setItem("cart", JSON.stringify(this.items));
    }
  }

  itemInclude(itemObj) {
    return this.items.some((item) => {
      return item.name === itemObj.name && item.price === itemObj.price;
    });
  }

  getItemIndex(itemObj) {
    return this.items.findIndex((item) => {
      return item.name === itemObj.name && item.price === itemObj.price;
    });
  }

  getIncludeItems(itemObj) {
    return this.items.filter((item) => {
      return item.name === itemObj.name && item.price === itemObj.price;
    })[0];
  }

  static itemDataMapper(item) {
    return {
      orderId: "",
      name: item.Name,
      itemId: item.id,
      price: item.price,
    };
  }

  setUserDetails(user) {
    this.#userDetails = user;
  }
  serUserAddress(address, number) {
    this.#userDetails = {
      ...this.#userDetails,
      address,
      number,
    };
  }
  setOrderType(type) {
    this.orderType = type;
  }

  setNote(note) {
    this.#note = note;
  }

  addIntoCart() {}
  get itemsTotal() {
    return this.items.reduce((count, item) => {
      return count + item.price * item.quantity;
    }, 0);
  }
  get orderTotal() {
    return (
      this.items.reduce((count, item) => {
        return count + item.price * item.quantity;
      }, 0) +
      (this.orderType === "Home" ? this.config?.deliveryCharges || 0 : 0)
    );
  }

  placeOrder() {
    const order = {
      restaurantUID: this.#restaurantUID,
      table: this.#table ?? "",
      status: JSON.stringify([
        { status: "pending", time: new Date().toISOString() },
      ]),
      userInfo: JSON.stringify(this.#userDetails),
      orderitems: JSON.stringify(this.items),
      orderType: this.orderType,
      deliveryCharges:
        this.orderType === "Home" ? this.config?.deliveryCharges || 0 : 0,
      subTotal: this.orderTotal,
      note: this.#note,
      createdAt: new Date().toISOString(),
    };
    this.#saveToDatabase(order);
  }

  clear() {
    this.#table = null;
    this.#userDetails = null;
    this.orderType = null;
    this.items = [];
    sessionStorage.removeItem("cart");
  }

  #saveToDatabase(data) {
    const myHeaders = new Headers();
    myHeaders.append("Basic", "somenu5050");
    const formdata = new FormData();

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formdata.append(key, data[key]);
      }
    }

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(API_BASE_URL + "orders/create", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.clear();
        window.location.href = "./my-orders";
        localStorage.setItem(
          "currentOrder",
          JSON.stringify({
            id: result.data.orderid,
            restaurantUID: this.#restaurantUID,
            time: new Date().toISOString(),
          })
        );
      })
      .catch((error) => console.log("error", error));
  }
}

export const orderService = new OrderService();
