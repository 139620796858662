export function loadScript(src, attr = {}) {
  return new Promise((resolve, reject) => {
    if (isScriptAlreadyLoaded(src)) {
      resolve(src + " Already loded");
      return;
    }
    const scriptTag = document.createElement("script");
    scriptTag.src = src;
    Object.keys(attr).forEach((key) => {
      scriptTag.setAttribute(key, attr[key]);
    });
    scriptTag.async = true;
    scriptTag.onload = () => resolve(src + "loded");
    scriptTag.onerror = () =>
      reject(new Error(`Failed to load script: ${src}`));
    document.head.appendChild(scriptTag);
  });
}
function isScriptAlreadyLoaded(src) {
  return [...document.querySelectorAll("script")].some(
    (script) => script.src === src
  );
}
