import _ from "lodash";
import { db } from "../../firebase";
import { loadScript } from "./script.services";
import { orderService } from "./orders.service";
export class MenuDataService {
  qrCode = "";
  isLodedAndViewMenu = false;
  constructor() {
    this.RestaurantDetails = null;
    this.ImageMenu = null;
    this.DigitalMenu = null;
    this.SelectedCategory = 0;
    this.isRestornetDataLoded = false;
    this.isMenuDataLoded = false;
    const path = window.location.pathname;
    if (path.includes("menu") || path.includes("cart")) this.getQueryParams();
  }
  getState() {
    return {
      imageMenu: this.ImageMenu,
      digitalMenu: this.DigitalMenu,
      selectedCategory: 0,
    };
  }

  dataLodeEnd = () => {
    return new Promise((resolve) => {
      const timeInterval = setInterval(() => {
        if (this.isMenuDataLoded) {
          clearInterval(timeInterval);
          resolve(this.getState());
        }
      }, 200);
    });
  };
  isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );
  restornetDataLoded = () => {
    return new Promise((resolve) => {
      const timeInterval = setInterval(async () => {
        if (this.isRestornetDataLoded) {
          clearInterval(timeInterval);
          if (!this.RestaurantDetails?.subscription && !this.isLocalhost) {
            await loadScript(
              "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6820424545583470"
            ).then(() => console.debug("Ads loded"));
          }
          console.debug(
            "timeInterval ~ this.RestaurantDetails:",
            this.RestaurantDetails
          );
          resolve({
            restaurantDetails: this.RestaurantDetails,
          });
        }
      }, 200);
    });
  };

  getQueryParams() {
    const queryParams = new URLSearchParams(window.location.search);
    console.log(queryParams)
    if (queryParams.has("id")) {
      this.qrCode = queryParams.get("id");
      console.debug("getQueryParams ~ qrCode:", this.qrCode);
      this.fetchRestorentByParamsId(queryParams.get("id"));
    } else if (queryParams.has("uid")) {
      this.fetchRestorentByParamsId(null, queryParams.get("uid"));
    } 
  }
  async getRestorentIdParamsId(paramsId) {
    return (await db.ref("userqrcode/" + paramsId).once("value")).val().id;
  }
  async fetchRestorentByParamsId(paramsId, restorentId = "") {
    try {
      if (!restorentId?.trim()) {
        restorentId = await this.getRestorentIdParamsId(paramsId);
      }
      if (restorentId) {
        const restaurantDetails = (
          await db.ref(`restaurants/${restorentId}`).once("value")
        ).val();
        orderService.setRestaurantUID(restorentId);
        orderService.setConfig(restaurantDetails?.orderingConfig);
        this.RestaurantDetails = restaurantDetails;
        this.isRestornetDataLoded = true;
        await this.fetchRestorentMenuItem(restorentId);
      } else {
        window.location.href = "404";
      }
    } catch (error) {
      window.location.href = "404";
    }
  }

  async fetchRestorentMenuItem(restorentId) {
    const digitalMenuRes = (
      await db.ref(restorentId + "/menu").once("value")
    ).val();
    const activeMenu = _.values(digitalMenuRes)
      .filter((menu) => {
        return menu.status;
      })
      .map((menu) => {
        const sortMenuItem = _.values(menu.items)
          .map((item) => {
            item.menu = _.values(item.menu);
            return item;
          })
          .sort((a, b) => a.index - b.index);
        return sortMenuItem;
      });
    this.DigitalMenu = activeMenu[0];
    this.isMenuDataLoded = true;
  }
}
