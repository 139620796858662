import firebase from "firebase";

// import firebase from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyA-f9XGGE2JY62IB3Cg6LsR9lLf1dnSfBw",
  authDomain: "somenu-da2f2.firebaseapp.com",
  databaseURL: "https://somenu-da2f2-default-rtdb.firebaseio.com",
  projectId: "somenu-da2f2",
  storageBucket: "somenu-da2f2.appspot.com",
  messagingSenderId: "986305005177",
  appId: "1:986305005177:web:bc601dcf6e7b20a20cf6a8",
  measurementId: "G-P3CP9FWW1P",
};

firebase.initializeApp(firebaseConfig);
// export const auth = firebase.auth;
export const db = firebase.database();
export const rfs = firebase.firestore();
export const auth = firebase.auth();
export const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;
