import React from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Suspense } from "react";
import { render } from "react-snapshot";
import * as ServiceWorkerRegistration from "./serviceWorkerRegistration";
export const API_BASE_URL = true ? "https://api.somenu.digital/index.php/" : "http://localhost/C3/index.php/";
// export const API_BASE_URL = "http://localhost:8080/";
render(
  <Suspense
    fallback={
      <div>
        <div className="splash" style={{ height: "100vh" }}></div>
      </div>
    }
  >
    <App />
  </Suspense>,
  document.getElementById("root")
);

ServiceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
